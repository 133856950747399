
import CloudFun, { Condition, defineComponent, Operator, reactive, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import { VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    var printColumns = [
      { field: "Number" },
      { field: "StockDate" },
      { field: "Warehouse.Name" },
      { field: "Submitter.Name" },
      { field: "Amount" },
      { field: "Status" },
    ];

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const pricePermissions = checkPermissions("Price");

    const gridOptions = reactive<GridOptions>({
      id: "supplier",
      title: "進貨單",
      multiselect: false,
      toolbarConfig: { custom: true, refresh: true },
      printConfig: { sheetName: "進貨清單", columns: printColumns, modes: ["current", "selected", "all"] },
      exportConfig: { filename: "進貨清單", type: "csv", types: ["html", "csv"], mode: "all", modes: ["current", "selected", "all"], columns: printColumns },
      columns: [
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, width: 100, sortable: true },
        {
          field: 'StockDate', title: '進貨日期', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        { field: 'Warehouse.Name', title: '入庫倉庫', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Submitter.Name', title: '提交人員', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Amount', title: '金額', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, width: 100, resizable: false, formatter: ({ cellValue }) => model ? Object.values(model.enums.PurchaseBillStatus).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      decideRowOperable: (row, operation) => row.Status < 30 || operation === 'read',
      promises: {
        query: model ? (params) => {
          params.sortings = params.sortings || [];
          // params.sortings.push({ column: 'StockDate', order: 1 });
          params.sortings.push({ column: 'Number', order: 1 });
          return model.dispatch("purchaseBill/query", params);
        } : undefined,
        queryAll: model ? () => model.dispatch("purchaseBill/query", { sortings: [{ column: 'StockDate', order: 1 }] }) : undefined,
        save: model ? (params) => model.dispatch("purchaseBill/save", params) : undefined,
      },
      modalConfig: { width: 800, showFooter: true },
    });

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: "Time", title: "入單日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "SupplierId", title: "供應商", span: 12, slots: { default: "column-supplier-id" } },
        { field: "InvoiceNumber", title: "發票號碼", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入發票號碼" } } },
        { field: "WarehouseId", title: "入庫倉庫", span: 12, slots: { default: "column-warehouse-id" } },
        { field: "StockDate", title: "進貨日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.PurchaseBillStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Submitter.Name', title: '提交人員', span: 12, itemRender: { name: '$input', props: { disabled: true } } },
        { field: "Amount", title: "金額", span: 12, itemRender: { name: "$input", props: { type: "number", disabled: true, controls: false } } },
        { field: "PurchaseOrder.Number", title: "採購單", span: 12, itemRender: { name: "$input", props: { disabled: true } } },
      ],
      rules: {
        Time: [{ required: true }],
        StockDate: [{ required: true }],
        WarehouseId: [{ required: true }]
      }
    };

    const supplierSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇供應商',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("supplier/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("supplier/query", params) // eslint-disable-line
      },
    }

    const warehouseSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇倉庫',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("warehouse/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("warehouse/query", params) // eslint-disable-line
      },
    }

    const productSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇產品',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true },
        { field: "Barcode", title: "條碼", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
        { field: "Brand.Name", title: "品牌", showHeaderOverflow: true, showOverflow: true, width: 110, sortable: true },
      ],
      promises: {
        find: (value) => model!.dispatch("product/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("product/query", params) // eslint-disable-line
      },
    }

    const detailGrid = ref<any>({});
    const detailGridOptions = reactive<GridOptions>({
      round: true,
      border: true,
      stripe: true,
      autoResize: true,
      sortConfig: { defaultSort: { field: "CreatedTime", order: "desc" } },
      mode: 'inline',
      multiselect: false,
      columns: [
        {
          field: "ProductId", title: "產品", sortable: true,
          slots: { default: "column-product-id" },
        },
        {
          field: "Price", title: "單價", sortable: true, width: "100", align: "right", resizable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 0, disabled: !pricePermissions },
            events: {
              input: (params, event) => { params.row.Price = event.value; params.row.Amount = params.row.Price * params.row.Quantity; },
            }
          }
        },
        {
          field: "Quantity", title: "數量", sortable: true, width: "100", align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 1 },
            events: {
              input: (params, event) => { params.row.Quantity = event.value; params.row.Amount = params.row.Price * params.row.Quantity; },
            }
          }
        },
        {
          field: "Amount", title: "金額", sortable: true, width: "100", headerAlign: "left", align: "right", resizable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
        },
      ],
      editRules: {
        ProductId: [{ required: true, message: "未選擇產品" }],
        Price: [
          { required: true, message: '未輸入單價' },
          { min: 0, message: '單價不得為負數' }
        ],
        Quantity: [
          { required: true, message: '未輸入數量' },
          { min: 1, message: '數量需大於零' }
        ],
      },
      promises: {
        query: model ? (params) => { params.condition = new Condition("PurchaseBillId", Operator.Equal, grid.value.editingRow?.Id || 0).and(params.condition!); return model.dispatch("purchaseBillItem/query", params); } : undefined,
        queryAll: model ? () => model.dispatch("purchaseBillItem/query") : undefined,
        save: model ? (params) => model.dispatch("purchaseBillItem/save", params).then(async () => { grid.value.editingRow.Amount = (await model.dispatch('purchaseBill/find', grid.value.editingRow.Id)).Amount; grid.value.refresh(); }) : undefined,
      },
      modalConfig: { height: "auto" },
    });

    return {
      grid,
      gridOptions,
      formOptions,
      supplierSelectOptions,
      warehouseSelectOptions,
      productSelectOptions,
      detailGrid,
      detailGridOptions,
      pricePermissions,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onAddItem(masterRow: any) {
      if (!masterRow.Id) {
        if (!confirm("新增項目須先建立此張進貨單，是否同意?")) return;
        try {
          const bill = await this.$model.dispatch("purchaseBill/insert", masterRow);
          Object.assign(masterRow, bill);
        } catch (e: any) {
          this.$send('error', e);
        }
      }
      this.detailGrid.addNewRow({
        PurchaseBillId: this.grid.editingRow.Id,
        Quantity: 1,
        Amount: 0
      });
    },
  }
});
